export default {
  data() {
    return {
      makeOptions: [],
      modelOptions: [],
      seriesOptions: [],
      engineOptions: [],
      transimissionOptions: [],
      modelTypeOptions: [],
      yearOptions: [],
      partTypeOptions: [],
      colorOptions: [],
      userOptions: [],
      customerOptions: [],
      pickerOptions: [],
      dispatcherOptions: [],
      deliveryDriverOptions: [],
      courierPickerOptions: [],
      trimColorOptions: [],
      expenseTypeOptions: [],
      paymentTypeOptions: [],
      stockOptions: [],
      stockPurchaseTypeOptions: [],
      writeOffOptions: [],
      plateOptions: [],
      adjustTypeOptions: [],
      courierOptions: [],
      group1Options: [],
      group2Options: [],
      regFlagOptions: [
        { id: 0, name: "UnRegistered" },
        { id: 1, name: "Registered" },
      ],
      monthOptions: [
        { value: 1, name: "01" },
        { value: 2, name: "02" },
        { value: 3, name: "03" },
        { value: 4, name: "04" },
        { value: 5, name: "05" },
        { value: 6, name: "06" },
        { value: 7, name: "07" },
        { value: 8, name: "08" },
        { value: 9, name: "09" },
        { value: 10, name: "10" },
        { value: 11, name: "11" },
        { value: 12, name: "12" },
      ],
      regStateOptions: [
        { id: 0, name: "NA" },
        { id: 1, name: "NZ" },
      ],
      courierPriorityOptions: [
        { id: 1, name: "Normal" },
        { id: 2, name: "Hight" },
        { id: 3, name: "NA" },
      ],
      priorityOptions: [
        { id: 1, name: "Normal", time: 24 },
        { id: 2, name: "Urgent", time: 5 },
        { id: 3, name: "Super Urgent", time: 1 },
      ],
      packingStatusOptions: [
        { id: 1, name: "Ready" },
        { id: 2, name: "In Progress" },
        { id: 3, name: "Picked" },
        { id: 4, name: "Packed" },
        { id: 5, name: "Picked Up" },
        { id: 6, name: "Cancelled" },
      ],
      countryOptions: [
        {
          id: 1,
          name: "New Zealand",
        },
      ],

      request: null,
    };
  },
  methods: {
    async getMakeOptions() {
      try {
        const result = await this.$api.common.getMakeList();

        const { code, data } = result;
        console.log("Fixx", { code, data });

        if (code === 200) {
          this.makeOptions = data;
        }
      } catch (err) {
        console.log("err", err.message);
        console.error(err.message);
      }
    },
    async getModelOptions(makeId) {
      try {
        const result = await this.$api.common.getModelList({ makeId });

        const { code, data } = result;

        if (code === 200) {
          this.modelOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getSeriesOptions(makeId, modelId) {
      try {
        const result = await this.$api.common.getSeriesList({
          makeId,
          modelId,
        });

        const { code, data } = result;

        if (code === 200) {
          this.seriesOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getEngineOptions(makeId) {
      try {
        const result = await this.$api.common.getEngineList({ makeId });

        const { code, data } = result;
        // console.log(data)
        if (code === 200) {
          this.engineOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getTransimissionOptions(makeId) {
      try {
        const result = await this.$api.common.getTransimisssionList({ makeId });

        const { code, data } = result;
        // console.log(data)
        if (code === 200) {
          this.transimissionOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getModelTypeOptions() {
      try {
        const result = await this.$api.common.getModelTypeList();

        const { code, data } = result;

        if (code === 200) {
          this.modelTypeOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getYearOptions() {
      try {
        const result = await this.$api.common.getYearList();

        const { code, data } = result;

        if (code === 200) {
          this.yearOptions = data.reverse();
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getPartTypeOptions() {
      try {
        const result = await this.$api.common.getPartTypeList();
        // console.log(result,'sss')
        const { code, data } = result;

        if (code === 200) {
          this.partTypeOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getColorOptions() {
      try {
        const result = await this.$api.common.getColorList({
          vehColourType: 1,
        });

        const { code, data } = result;
        // console.log(result,'color')
        if (code === 200) {
          this.colorOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getUserOptions() {
      try {
        const result = await this.$api.common.getUserList();

        const { code, data } = result;
        // console.log(result,'color')
        if (code === 200) {
          this.userOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getTrimColourOptions() {
      try {
        const result = await this.$api.common.getColorList({
          vehColourType: 2,
        });

        const { code, data } = result;
        // console.log(result,'color')
        if (code === 200) {
          this.trimColorOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getExpenseTypeOptions() {
      try {
        const result = await this.$api.common.getExpenseTypeList();

        const { code, data } = result;
        if (code === 200) {
          this.expenseTypeOptions = data;
          this.form.purchaseDetail.expenseTypeId = data.find(
            (item) => item.active === "Y"
          )?.id;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getPaymentTypeOptions() {
      try {
        const result = await this.$api.common.getPaymentTypeList();
        const { code, data } = result;
        if (code === 200) {
          this.paymentTypeOptions = data;
          this.form.purchaseDetail.paymentTypeId = data.find(
            (item) => item.active === "Y"
          ).paymentId;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getStockOptions() {
      try {
        const result = await this.$api.common.getStockTypeList();
        const { code, data } = result;
        if (code === 200) {
          this.stockOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getStockPurchaseTypeOptions() {
      try {
        const result = await this.$api.common.getStockPurchaseTypeList();
        const { code, data } = result;
        if (code === 200) {
          this.stockPurchaseTypeOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getWriteOffOptions() {
      try {
        const result = await this.$api.common.getWriteOffList();
        const { code, data } = result;
        if (code === 200) {
          this.writeOffOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getPlateOptions() {
      try {
        const result = await this.$api.common.getPlateList();
        const { code, data } = result;
        if (code === 200) {
          this.plateOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getAdjustTypeOptions() {
      try {
        const adjustTypeResult = await this.$api.common.getAdjustTypeList();
        const { code, data } = adjustTypeResult;

        if (code === 200) {
          this.adjustTypeOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getCourierOptions() {
      try {
        const courierResult = await this.$api.common.getCourierList({
          name: "",
        });
        const { code, data } = courierResult;

        if (code === 200) {
          this.courierOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getGroup1Options() {
      try {
        const group1Result = await this.$api.common.getGroup1List();
        const { code, data } = group1Result;

        if (code === 200) {
          this.group1Options = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getGroup2Options() {
      try {
        const group2Result = await this.$api.common.getGroup2List();
        const { code, data } = group2Result;

        if (code === 200) {
          this.group2Options = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getPickerOptions() {
      try {
        const res = await this.$api.picking.getPicker();
        const { code, data } = res;

        if (code === 200) {
          this.pickerOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getDispatcherOptions() {
      try {
        const res = await this.$api.picking.getDispatcher();
        const { code, data } = res;

        if (code === 200) {
          this.dispatcherOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getDeliveryDriverOptions() {
      try {
        const res = await this.$api.picking.getDeliveryDriver();
        const { code, data } = res;

        if (code === 200) {
          this.deliveryDriverOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getCourierPickerOptions() {
      try {
        const res = await this.$api.user.getPickUserList();
        const { code, data } = res;

        if (code === 200) {
          this.courierPickerOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    getSignal() {
      if (this.request) {
        this.request.abort();
      }
      this.request = new AbortController();
      return this.request.signal;
    },
  },
};
